body {
  margin: 0;
  padding: 0;
  font-style: normal;
  -webkit-overflow-scrolling: touch;
  -ms-scroll-chaining: none;
      overscroll-behavior: none;
  overscroll-behavior-x: none;
  max-width: 100%;
  font-size: 0.95rem;
  font-family: 'Noto Sans JP', sans-serif;
}
img {
    image-orientation: from-image;
}
@-webkit-keyframes wave {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1600px;
  }
  100% {
    margin-left: 0px;
  }
}
@keyframes wave {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1600px;
  }
  100% {
    margin-left: 0px;
  }
}

@-webkit-keyframes swell {
  0% {
    margin-left: 0;
    -webkit-transform: translate3d(0,25px,0);
            transform: translate3d(0,25px,0);
  }
  25% {
    -webkit-transform: translate3d(0,5px,0);
            transform: translate3d(0,5px,0);
  }
  50% {
    margin-left: -1600px;
    -webkit-transform: translate3d(0,25px,0);
            transform: translate3d(0,25px,0);
  }
  75% {
    -webkit-transform: translate3d(0,5px,0);
            transform: translate3d(0,5px,0);
  }
  100% {
    margin-left: 0;
    -webkit-transform: translate3d(0,25px,0);
            transform: translate3d(0,25px,0);
  }
}

@keyframes swell {
  0% {
    margin-left: 0;
    -webkit-transform: translate3d(0,25px,0);
            transform: translate3d(0,25px,0);
  }
  25% {
    -webkit-transform: translate3d(0,5px,0);
            transform: translate3d(0,5px,0);
  }
  50% {
    margin-left: -1600px;
    -webkit-transform: translate3d(0,25px,0);
            transform: translate3d(0,25px,0);
  }
  75% {
    -webkit-transform: translate3d(0,5px,0);
            transform: translate3d(0,5px,0);
  }
  100% {
    margin-left: 0;
    -webkit-transform: translate3d(0,25px,0);
            transform: translate3d(0,25px,0);
  }
}

@-webkit-keyframes swell2 {
  0% {
    margin-left: 0;
    -webkit-transform: translate3d(0,15px,0);
            transform: translate3d(0,15px,0);
  }
  25% {
    -webkit-transform: translate3d(0,0px,0);
            transform: translate3d(0,0px,0);
  }
  50% {
    margin-left: -1600px;
    -webkit-transform: translate3d(0,15px,0);
            transform: translate3d(0,15px,0);
  }
  75% {
    -webkit-transform: translate3d(0,5px,0);
            transform: translate3d(0,5px,0);
  }
  100% {
    margin-left: 0;
    -webkit-transform: translate3d(0,15px,0);
            transform: translate3d(0,15px,0);
  }
}

@keyframes swell2 {
  0% {
    margin-left: 0;
    -webkit-transform: translate3d(0,15px,0);
            transform: translate3d(0,15px,0);
  }
  25% {
    -webkit-transform: translate3d(0,0px,0);
            transform: translate3d(0,0px,0);
  }
  50% {
    margin-left: -1600px;
    -webkit-transform: translate3d(0,15px,0);
            transform: translate3d(0,15px,0);
  }
  75% {
    -webkit-transform: translate3d(0,5px,0);
            transform: translate3d(0,5px,0);
  }
  100% {
    margin-left: 0;
    -webkit-transform: translate3d(0,15px,0);
            transform: translate3d(0,15px,0);
  }
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    -webkit-transition: opacity 100ms ease-out;
    transition: opacity 100ms ease-out;
    opacity: 1;
}

.fade-exit {
    -webkit-transition: opacity 100ms ease-in;
    transition: opacity 100ms ease-in;
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
}

.fade2-enter {
  opacity:.01;
  -webkit-transform:translateY(50%) scale(.95,.95);
          transform:translateY(50%) scale(.95,.95);
  -webkit-transition:all 1s ease-out;
  transition:all 1s ease-out;
}

.fade2-leave {
  opacity:1;
  -webkit-transform:translateY(0) scale(1,1);
          transform:translateY(0) scale(1,1);
  -webkit-transition:all .2s ease-out;
  transition:all .2s ease-out;
}

.fade2-enter-active {
  opacity:1;
  -webkit-transform:translateY(0) scale(1,1);
          transform:translateY(0) scale(1,1);
}

.fade2-leave-active {
  opacity:.01;
  -webkit-transform:translateY(5%) scale(.98,.98);
          transform:translateY(5%) scale(.98,.98);
}

@-webkit-keyframes fadein {
from {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
}

@keyframes fadein {
from {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}
}



.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: #142946;
  font-size: 8px;
  margin: 20px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@-webkit-keyframes idle-tab-anim {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  
  100% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}

@keyframes idle-tab-anim {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  
  100% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}

@-webkit-keyframes rotate-anim {
  0% { 
    -webkit-transform: rotate(-20deg); 
            transform: rotate(-20deg);
  }
  100% { 
    -webkit-transform: rotate(20deg); 
            transform: rotate(20deg);
  }
}

@keyframes rotate-anim {
  0% { 
    -webkit-transform: rotate(-20deg); 
            transform: rotate(-20deg);
  }
  100% { 
    -webkit-transform: rotate(20deg); 
            transform: rotate(20deg);
  }
}
@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}
